import { __assign, __spreadArray } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { useSize } from 'ahooks';
import { createForm, onFormInputChange } from '@formily/core';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { FormProvider, ExpressionScope } from '@formily/react';
import { createScope } from './components/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnIcon } from '@/components/cn-icon';
import { responsiveSizeH, responsiveSizeV } from './const';
import { FilterSelectedTags } from './components/filter-selected-tags';
import { ConfigModal } from './components/save-config';
import { SaveSelector, InputSaveOverlay } from './components/save-selected';
import { CnFilterGrid } from './components/cn-filter-grid';
import { FilterItemBtns } from './components/filter-item-btns';
import { JSXField } from './components/jsx-field';
import { CnFilterProItem } from './components/cn-filter-pro-item';
import { useSaveSelected } from './hooks/use-save-selected';
import { useSaveConfig } from './hooks/use-save-config';
import { useGap } from './hooks/use-gap';
import { isInvisible, calcLayout, calcLayoutWithButton } from './utils';
import { FilterRefsContext, FilterPropsContext } from './context';
import './index.scss';
import { SaveSelectedActions } from './store';
import { setCache, removeCache } from './cache';
import { HocBaseComponents, Plugin, pluginManager, safeCallFunction, } from '@/components/cn-utils';
export var CnFilterPro = React.forwardRef(function (props, ref) {
    var style = props.style, className = props.className, form = props.form, formProps = props.formProps, components = props.components, propsSchema = props.schema, scope = props.scope, children = props.children, $i18n = props.$i18n, extendButtons = props.extendButtons, extendButtonsPosition = props.extendButtonsPosition, expandButtonUseIcon = props.expandButtonUseIcon, resetButtonUseIcon = props.resetButtonUseIcon, columns = props.columns, responsiveSize = props.responsiveSize, columnGap = props.columnGap, rowGap = props.rowGap, colon = props.colon, onColumnChange = props.onColumnChange, enableExpand = props.enableExpand, maxVisibleRow = props.maxVisibleRow, defaultExpand = props.defaultExpand, propsExpand = props.expand, onExpandChange = props.onExpandChange, showSelected = props.showSelected, showFolder = props.showFolder, showBottomLine = props.showBottomLine, storageKey = props.storageKey, onGetStorage = props.onGetStorage, onSetStorage = props.onSetStorage, enableConfig = props.enableConfig, defaultConfigValue = props.defaultConfigValue, configValue = props.configValue, propsOnConfigValueChange = props.onConfigValueChange, enableSaveSelected = props.enableSaveSelected, saveSelectSpan = props.saveSelectSpan, beforeGetSaveSelected = props.beforeGetSaveSelected, beforeSetSaveSelected = props.beforeSetSaveSelected, buttonType = props.buttonType, onChange = props.onChange, onSearch = props.onSearch, onSubmit = props.onSubmit, onReset = props.onReset, fullWidth = props.fullWidth, labelAlign = props.labelAlign, labelTextAlign = props.labelTextAlign, size = props.size, rtl = props.rtl, labelCol = props.labelCol, wrapperCol = props.wrapperCol, moreFilters = props.moreFilters, useLabelForErrorMessage = props.useLabelForErrorMessage, requestConfig = props.requestConfig, cacheSearch = props.cacheSearch, isRightButton = props.isRightButton, buttonSpan = props.buttonSpan, hideButton = props.hideButton, removeEmptyLabel = props.removeEmptyLabel;
    var _a = React.useState(1), _ = _a[0], forceUpdate = _a[1];
    var _b = React.useState(false), fold = _b[0], setFold = _b[1];
    var filterRef = React.useRef();
    var resizeRef = React.useRef();
    var filterPopupContainerRef = React.useRef();
    var saveSelectedStateRef = React.useRef();
    var expandClickRef = React.useRef(false);
    React.useImperativeHandle(ref, function () { return resizeRef.current; }, [resizeRef]);
    var RealSchemaField = React.useMemo(function () { return createScope(scope); }, [scope]);
    var _c = React.useState(), formInstance = _c[0], setFormInstance = _c[1];
    // schema变化前的form values
    var formInstanceValuesRef = React.useRef();
    var _d = React.useState(defaultExpand || false), expand = _d[0], setExpand = _d[1];
    var defaultValuesRef = React.useRef((formInstance && formInstance.getState().values) || {});
    var searchValuesRef = React.useRef((formInstance && formInstance.getState().values) || {});
    var innerValues = formInstance && formInstance.getState().values;
    var _e = React.useState(propsSchema || {}), schema = _e[0], setSchema = _e[1];
    var _f = React.useState(), schemaChanged = _f[0], setSchemaChanged = _f[1];
    React.useEffect(function () {
        if (propsSchema) {
            setSchema(propsSchema);
        }
    }, [propsSchema]);
    React.useEffect(function () {
        if (propsExpand !== undefined && propsExpand !== expand) {
            setExpand(propsExpand);
        }
    }, [expand, propsExpand]);
    var handleToggleMore = React.useCallback(function () {
        expandClickRef.current = true;
        onExpandChange && onExpandChange(!expand);
        if (propsExpand === undefined) {
            setExpand(!expand);
        }
    }, [propsExpand, expand, onExpandChange]);
    var width = (useSize(resizeRef) || {}).width;
    var innerColumns = React.useMemo(function () {
        if (columns)
            return columns;
        var _responsive = responsiveSize ||
            (labelAlign === 'left' ? responsiveSizeH : responsiveSizeV);
        var matched = _responsive.length; // 默认8个
        if (!width)
            return matched;
        _responsive.some(function (v, idx) {
            if (width < v) {
                matched = idx;
                return true;
            }
            return false;
        });
        // 最小值3
        return matched > 3 ? matched : 3;
    }, [width, schema]);
    React.useEffect(function () {
        onColumnChange && onColumnChange(Number(innerColumns));
    }, [innerColumns, onColumnChange]);
    var handleSearch = function () {
        formInstance.validate().then(function () {
            searchValuesRef.current = formInstance.getState().values;
            setCache(searchValuesRef.current, storageKey, cacheSearch);
            onSearch && onSearch(formInstance.getState().values);
            // searchValuesRef更新了, 需要触发tags渲染
            forceUpdate(function (s) { return (s + 1) % 32; });
        }, function (error) {
            console.log(error);
        });
    };
    var handleSubmit = function (e) {
        e && e.preventDefault();
        formInstance.submit(onSubmit);
        handleSearch();
    };
    var handleReset = function () {
        formInstance && formInstance.reset('*');
        formInstance &&
            (defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current) &&
            formInstance.setValues(defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current);
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate(function (s) { return (s + 1) % 32; });
        if (onReset) {
            return onReset();
        }
        handleChange(formInstance.getState().values);
        handleSearch();
    };
    var handleChange = function (values) {
        onChange && onChange(values);
    };
    var handleRemove = function (key) {
        formInstance && formInstance.reset(key);
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate(function (s) { return (s + 1) % 32; });
        handleChange(formInstance.getState().values);
        handleSearch();
    };
    var getSearchValues = function () { return searchValuesRef.current; };
    var filterRefContext = React.useRef({
        update: function () { return undefined; },
        itemCollection: {},
        filterRef: filterRef,
        overlayRef: React.useRef(null),
        currentSelectedValuesRef: React.useRef(null),
        formInstance: {},
        refreshed: false,
    });
    if (formInstance) {
        formInstance.filterSubmit = handleSubmit;
        formInstance.filterReset = handleReset;
        formInstance.filterSearch = handleSearch;
        formInstance.filterChange = handleChange;
        formInstance.getSearchValues = getSearchValues;
    }
    var initialChildren = React.useMemo(function () {
        if (!schema || !schema.properties)
            return [];
        return Object.keys(schema.properties).map(function (v, i) { return ({
            key: v,
            value: __assign({}, schema.properties[v]),
        }); });
    }, [schema]);
    var _g = useSaveConfig({
        enableConfig: enableConfig,
        defaultConfigValue: defaultConfigValue,
        configValue: configValue,
        storageKey: storageKey,
        children: initialChildren,
        onGetStorage: onGetStorage,
        onSetStorage: onSetStorage,
    }), saveConfigState = _g[0], saveConfigDispatch = _g[1];
    var _h = useSaveSelected({
        enableSaveSelected: enableSaveSelected,
        values: searchValuesRef.current,
        storageKey: storageKey,
        onGetStorage: onGetStorage,
        onSetStorage: onSetStorage,
        filterContext: filterRefContext.current,
    }), saveSelectedState = _h[0], saveSelectedDispatch = _h[1];
    var arrangedChildren = saveConfigState.arrangedChildren;
    var showAll = !enableExpand || expand;
    // 计算展示和隐藏元素
    var _j = React.useMemo(function () {
        return isRightButton
            ? calcLayoutWithButton(showAll
                ? Object.keys(schema.properties).length
                : Number(maxVisibleRow) || 2, Number(innerColumns), Number(buttonSpan) || 1, arrangedChildren, enableSaveSelected, saveSelectSpan, filterRefContext)
            : calcLayout(showAll
                ? Object.keys(schema.properties).length
                : Number(maxVisibleRow) || 2, Number(innerColumns), arrangedChildren, enableSaveSelected, saveSelectSpan, filterRefContext);
    }, [
        showAll,
        innerColumns,
        maxVisibleRow,
        schema,
        arrangedChildren,
        enableSaveSelected,
        saveSelectSpan,
        // _, //用于修正搜索时候导致屏幕闪动的问题，其他影响未知，需要发beta版本测试
    ]), showChildren = _j.showChildren, hideChildren = _j.hideChildren;
    var filterPropsContext = React.useMemo(function () {
        return __assign(__assign({}, props), { cols: (props.columns || innerColumns), showAll: showAll, expand: expand, hideChildren: hideChildren, handleToggleMore: handleToggleMore, saveSelectedState: saveSelectedState, saveSelectedDispatch: saveSelectedDispatch, saveConfigState: saveConfigState, saveConfigDispatch: saveConfigDispatch, rowGap: useGap(size, rowGap), columnGap: useGap(size, columnGap), rtl: rtl });
    }, [
        props,
        showAll,
        expand,
        hideChildren,
        handleToggleMore,
        innerColumns,
        saveSelectedState,
        saveSelectedDispatch,
        saveConfigState,
        saveConfigDispatch,
        size,
        rtl,
    ]);
    var realSchema = React.useMemo(function () {
        var properties = {};
        __spreadArray(__spreadArray([], showChildren, true), hideChildren.map(function (child) { return (__assign(__assign({}, child), { value: __assign(__assign({}, child.value), { isFold: true }) })); }), true).map(function (_a, idx) {
            var key = _a.key, value = _a.value;
            properties[key] = __assign(__assign({}, value), { 'x-index': idx + 1 });
        });
        return {
            type: 'object',
            properties: properties,
        };
    }, [showChildren, hideChildren]);
    var applyValues = function (f, hiddenKeys, mode) {
        if (mode === void 0) { mode = 'merge'; }
        if (!formInstanceValuesRef.current)
            return;
        // 布局更新（屏幕尺寸调整/查询项调整）时，重新应用上次的 form values
        Object.keys(formInstanceValuesRef.current).map(function (k) {
            if (hiddenKeys.includes(k)) {
                delete formInstanceValuesRef.current[k];
            }
        });
        f.setValues(formInstanceValuesRef.current, mode);
    };
    var handleConfigValueChange = function (value) {
        formInstance &&
            applyValues(formInstance, value
                .map(function (_a) {
                var visible = _a.visible, name = _a.name;
                if (!visible)
                    return name;
            })
                .filter(Boolean), 'overwrite');
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate(function (s) { return (s + 1) % 32; });
        handleChange(formInstance.getState().values);
        safeCallFunction(propsOnConfigValueChange, value);
    };
    React.useEffect(function () {
        if (!cacheSearch) {
            // 关闭时 清空一次session值
            removeCache(storageKey);
        }
    }, [cacheSearch]);
    React.useEffect(function () {
        // 如果是点击 展开/收起 造成的 realSchema 更新，单独处理以避免刷新
        if (expandClickRef.current && formInstance) {
            expandClickRef.current = false;
            var filterGrid = formInstance.query('filterGrid').take();
            filterGrid &&
                filterGrid.setComponentProps({
                    realSchema: realSchema,
                });
            return;
        }
        // 刷新form要在realSchema更新后的nexttick中，否则不能生效
        setSchemaChanged({});
    }, [form, realSchema]);
    React.useEffect(function () {
        // 记住上次 form values
        if (formInstance && formInstance.getState) {
            formInstanceValuesRef.current = formInstance.getState().values;
        }
        var newForm = null;
        if (form) {
            newForm = form;
            searchValuesRef.current = form.getState().values;
        }
        else {
            newForm = createForm(formProps);
        }
        // 需要根据处理动态显隐的 field
        var filterGrid = newForm.query('filterGrid').take();
        if (filterGrid && !filterRefContext.current.refreshed) {
            filterRefContext.current.refreshed = true;
            expandClickRef.current = true;
            forceUpdate(function (s) { return (s + 1) % 32; });
        }
        else {
            setFormInstance(newForm);
        }
        // setFormInstance(newForm);
    }, [schemaChanged]);
    React.useEffect(function () {
        if (!formInstance || !formInstanceValuesRef.current)
            return;
        filterRefContext.current.formInstance = formInstance;
        applyValues(formInstance, arrangedChildren
            .map(function (_a) {
            var key = _a.key, value = _a.value;
            if (isInvisible(value))
                return key;
        })
            .filter(Boolean));
    }, [formInstance]);
    React.useEffect(function () {
        // 查询项有变化时 触发onchange 并 清空查询习惯
        saveSelectedStateRef.current = saveSelectedState;
        formInstance === null || formInstance === void 0 ? void 0 : formInstance.addEffects('onFormInputChange', function () {
            onFormInputChange(function (f) {
                handleChange(f.getState().values);
                saveSelectedDispatch(SaveSelectedActions.clearSelected(saveSelectedStateRef.current, {
                    values: f.getState().values,
                }));
            });
        });
    }, [formInstance, saveSelectedState]);
    var isInitRemote = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    var readyRequestConfig = React.useMemo(function () { return (__assign(__assign({}, requestConfig), { ready: isInitRemote })); }, [requestConfig, isInitRemote]);
    var _k = useCnRequest(readyRequestConfig), data = _k.data, loading = _k.loading;
    React.useEffect(function () {
        if (!isInitRemote)
            return;
        if (loading)
            return;
        formInstance === null || formInstance === void 0 ? void 0 : formInstance.setValues(data || {});
        handleChange(formInstance === null || formInstance === void 0 ? void 0 : formInstance.getState().values);
    }, [data]);
    // 渲染 JSON Schema
    var renderFilterSchema = function () {
        if (!width || !realSchema || !realSchema.properties)
            return null;
        return (React.createElement(RealSchemaField, { scope: scope, components: components },
            React.createElement(RealSchemaField.Void, { name: "filterGrid", "x-component-props": {
                    realSchema: realSchema,
                }, "x-component": CnFilterGrid },
                enableSaveSelected && (React.createElement(RealSchemaField.Void, { name: "saveSelector", "x-component": SaveSelector, "x-decorator-props": {
                        colSpan: saveSelectSpan,
                    }, "x-index": 0 })),
                Object.keys(realSchema.properties).map(function (n) {
                    return (React.createElement(RealSchemaField.Markup, __assign({ name: n }, realSchema.properties[n])));
                }),
                !hideButton && isRightButton && (React.createElement(RealSchemaField.Void, { name: "rightButton", "x-component": FilterItemBtns, "x-decorator": CnFilterProItem, "x-decorator-props": {
                        colSpan: buttonSpan,
                        className: 'cn-ui-filter-pro-right-button',
                    } })))));
    };
    if (!formInstance) {
        return React.createElement("div", null, "Form \u521D\u59CB\u5316\u5B9E\u4F8B\u4E2D...");
    }
    return (React.createElement(FormProvider, { form: formInstance },
        React.createElement(ExpressionScope, { value: { $$form: formInstance } },
            React.createElement(FilterRefsContext.Provider, { value: filterRefContext.current },
                React.createElement(FilterPropsContext.Provider, { value: filterPropsContext },
                    React.createElement("div", { "data-name": "CnFilter", 
                        // id="filter-popup-container"
                        className: cx('cn-ui-filter-pro', className, "cn-ui-filter-pro-".concat(size)), style: style, ref: resizeRef, onKeyDown: function (e) {
                            var _a, _b;
                            if (((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.tagName) === null || _b === void 0 ? void 0 : _b.toLocaleUpperCase()) === 'INPUT' &&
                                ((e === null || e === void 0 ? void 0 : e.key) === 'Enter' ||
                                    ((e === null || e === void 0 ? void 0 : e.keyCode) || (e === null || e === void 0 ? void 0 : e.which) || (e === null || e === void 0 ? void 0 : e.charCode)) === 13)) {
                                handleSubmit(e);
                            }
                        } },
                        React.createElement(ConfigModal, { enableConfig: enableConfig, enableSaveSelected: enableSaveSelected, onConfigValueChange: handleConfigValueChange, configValue: configValue, store: saveConfigState, dispatch: saveConfigDispatch, gridProps: {
                                columns: innerColumns,
                                gap: [8, 8],
                            }, saveSelectSpan: saveSelectSpan }),
                        React.createElement(InputSaveOverlay, { enableSaveSelected: enableSaveSelected, values: searchValuesRef.current, beforeGetSaveSelected: beforeGetSaveSelected, store: saveSelectedState, dispatch: saveSelectedDispatch }),
                        React.createElement("div", { style: { display: fold ? 'none' : 'inherit' } },
                            React.createElement(CnFormLayout, __assign({}, {
                                colon: colon,
                                labelAlign: labelAlign,
                                labelTextAlign: labelTextAlign,
                                labelCol: labelCol,
                                wrapperCol: wrapperCol,
                                size: size,
                                removeEmptyLabel: removeEmptyLabel,
                            }),
                                renderFilterSchema(),
                                React.createElement(JSXField, __assign({}, {
                                    propsSchema: propsSchema,
                                    schema: schema,
                                    setSchema: setSchema,
                                    children: children,
                                    formInstance: formInstance,
                                }))),
                            hideButton || isRightButton ? null : React.createElement(FilterItemBtns, null)),
                        showSelected ? (React.createElement(FilterSelectedTags, { values: searchValuesRef.current, innerValues: innerValues, onRemove: handleRemove })) : null,
                        showFolder ? (React.createElement("div", { className: "cn-ui-filter-foldline" },
                            React.createElement("div", { className: "cn-ui-filter-foldline-btn", onClick: function () {
                                    setFold(!fold);
                                } },
                                React.createElement(CnIcon, { className: "cn-ui-filter-foldline-btn-icon", type: fold ? 'icon-arrow-down' : 'icon-arrow-up', size: "small" }),
                                fold
                                    ? $i18n.get({ id: 'Expand', dm: '展开', ns: 'CnFilter' })
                                    : $i18n.get({
                                        id: 'PutItAway',
                                        dm: '收起',
                                        ns: 'CnFilter',
                                    })))) : null,
                        !showFolder && showBottomLine ? (React.createElement("div", { className: "cn-ui-filter-foldline" })) : null,
                        !showSelected && !showFolder && !showBottomLine && (React.createElement("div", { className: "cn-ui-filter-marginbottom" })),
                        React.createElement("div", { "data-role": "filter-popup-container", ref: filterPopupContainerRef })))))));
});
export var CnFilterProPlugin = React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var plugin = new Plugin('CnFilterPro', pluginManager);
        plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnFilterPro')
            .map(function (item) { return item.method; }));
        return plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnFilterPro, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnFilterPro, { props: props, plugins: plugins, ref: ref });
});
CnFilterPro.defaultProps = {
    extendButtonsPosition: 'end',
    expandButtonUseIcon: false,
    resetButtonUseIcon: false,
    isRightButton: false,
    buttonSpan: 1,
    enableExpand: true,
    maxVisibleRow: 2,
    showSelected: false,
    showFolder: true,
    showBottomLine: false,
    enableConfig: false,
    enableSaveSelected: false,
    saveSelectSpan: 1,
    size: 'medium',
    useLabelForErrorMessage: true,
};
