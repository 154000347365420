import isNil from 'lodash/isNil';
export default function isEmpty(value) {
    if (isNil(value)) {
        return true;
    }
    if (value === '') {
        return true;
    }
    if (Array.isArray(value) && !value.length) {
        return true;
    }
    return false;
}
